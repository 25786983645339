.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

#header-container {
  background-color: white;
}

#header-container .site-header {
  font-weight: bold;
  font-family: "Times New Roman", Times, serif;
  line-height: 1;
}

#header-container .main-header {
  font-size: 64px;
}

#header-container .sub-header {
  font-size: 32px;
}

#header-container .header-line {
  margin: auto;
  background: #212529;
}

#left-menu-container {
  height: 300px;
}

#left-menu-container .left-menu-list > li {
  list-style-type: none;
  text-align: left;
  height: 50px;
}

#left-menu-container .left-menu-list .left-menu-item {
  text-decoration: none;
  font-weight: bold;
  line-height: 50px;
  color: #535a60;
  list-style-type: none;
}

.content-container {
  border-radius: 10px;
  border: 1px solid grey;
  box-shadow: 5px 10px #888888;
  -moz-box-shadow: 3px 3px 3px 3px #888888;
  -webkit-box-shadow: 3px 3px 3px 3px #888888;
  padding: 30px;
  margin-bottom: 15px;
}

.content-container .item-header {
  margin-bottom: 30px;
  font-size: 20px;
}

.content-container .item-header .item-header-left-item {
  font-weight: bold;
}

.content-container .item-header .item-header-right-item {
  color: grey;
  float: right;
}

#right-menu-container .right-panel-item {
  width: 200px;
  margin: auto;
  display: block;
  margin-bottom: 40px;
}

#projects-content #killallaliens {
  width: 1100px;
  height: 800px;
  border: none;
  display: block;
  margin: auto;
}
